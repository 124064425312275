.navbar-bottom {
  width: 100%;
  margin-top: 10px;
  position: relative;
  &__list {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;

    &-item-link {
      color: $white;
      text-decoration: none;
      font-size: 1.1rem;
      letter-spacing: 1px;
      font-weight: 600;
      padding: 8px 20px;
      text-transform: uppercase;
      position: relative;
      display: block;

      &:hover {
        color: $hover-link;
      }
      &:hover .category {
        display: block;
      }

      & .arrow-down {
        margin-left: 4px;
        position: absolute;
        top: 10px;
      }
      & .category {
        position: absolute;
        top: 100%;
        @include category;
        transform-origin: 50% 0;
        animation: $show ease 0.2s;
        display: none;
        width: 200px;
        &__item-link {
          @include category-link;
          position: relative;
          & .arrow-right {
            float: right;
          }

          & .sub-category {
            position: absolute;
            left: 100%;
            top: 0;
            @include category;
            display: none;
            transform-origin: 0 20%;
            animation: $show linear 0.2s;
            z-index: 1;
            &__item-link {
              @include category-link;

              &:hover {
                color: $hover-link;
              }
            }
          }

          &:hover {
            color: $hover-link;
          }
          &:hover .sub-category {
            display: block;
          }
        }
      }
    }
    &-item:first-child &-item-link {
      padding-left: 0;
    }

    &-item:last-child {
      background: rgb(224, 25, 25);
    }
  }
}

@media (min-width: 757px) and (max-width: 1024px) {
  .navbar-bottom {
    &__list {
      animation: $show linear 0.25s;
      transform-origin: top left;
      background-color: $color1;
      display: none;
      &-item-link {
        & .category {
          display: block;
          position: static;
          width: 50%;
          background-color: $color1;
        }
      }
      &-item:first-child &-item-link {
        padding-left: 20px;
      }
      &.open-menu {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 756px) {
  .navbar-bottom {
    &__list {
      animation: $show linear 0.25s;
      transform-origin: top left;
      background-color: $color1;
      display: none;
      &-item-link {
        & .category {
          display: block;
          position: static;
          background-color: $color1;
        }
      }
      &-item:first-child &-item-link {
        padding-left: 20px;
      }
      &.open-menu {
        display: block;
      }
    }
  }
}
