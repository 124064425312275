@mixin backgroundHeader {
  background-image: linear-gradient(0, $color1, $color2);
}

@mixin category {
  background-color: $color1;
  list-style: none;
  width: 180px;
}
@mixin category-link {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
  color: $white;
}

@mixin title-center {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 40px;
  margin-top: 45px;
  text-transform: uppercase;
  text-align: center;
  color: #555555;
}

@mixin title-center-1024 {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.6rem;
  margin-bottom: 20px;
  margin-top: 25px;
  text-transform: uppercase;
  text-align: center;
  color: #555555;
}

@mixin text-title {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: $primary-text;
}

@mixin text-description {
  font-size: 0.9rem;
  font-weight: 400;
  color: $second-text;
}

@mixin label {
  background-color: $label-color;
  user-select: none;
  color: $white;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
@mixin text-over {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@mixin remove-a {
  text-decoration: none;
}

@mixin banner-before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -10%;
  width: 120%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  content: "";
  transition: transform 0.8s;
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 60deg)
    translate3d(0, 125%, 0);
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
}
@mixin banner-before-hover {
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 60deg)
    translate3d(0, -125%, 0);
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: initial;
}

@mixin overflow-vertical {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin heading-text {
  font-size: 2rem;
  text-transform: uppercase;
  color: $primary-text;
  padding: 12px 16px;
  border-bottom: 1px solid #ccc;
}

@mixin section-detail-header {
  &-header {
    display: flex;
    align-items: center;
    &__title {
      padding: 8px 16px;
      @include text-title;
      border: 2px solid #e6ae47;
      margin-right: 12px;
      cursor: pointer;
      &:hover {
        background: #eecf95;
      }
    }
    &__separate {
      flex: 1;
      height: 2px;
      background-color: #e6ae47;
    }
  }
}
@mixin responsive($min-width, $max-width) {
  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}
