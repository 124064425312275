.checkout {
  & .wrapper {
    box-shadow: 0px 1px 5px #ccc;
    background-color: #e4d9fe30;

    &__heading {
      @include heading-text;
    }
    &__body {
      &-info {
        &__title {
          padding: 16px;
          font-size: 1.5rem;
          text-transform: uppercase;
          border-bottom: 1px solid #ccc;
        }
        &__body {
          padding: 20px 16px;
          display: flex;
          align-items: center;
          & .photo {
            & img {
              width: 80px;
              border-radius: 50%;
            }
          }
          & .information {
            padding: 16px;
            &-name {
              @include text-over;
              width: 300px;
              font-size: 1.2rem;
              font-weight: 600;
            }
            &-phone {
              @include text-over;
              width: 300px;
              font-size: 1.2rem;
            }
          }
        }
        &__address {
          padding: 20px 16px;
        }
        &__payments {
          border-top: 1px solid #ccc;
          margin-top: 40px;
          & .payments-title {
            font-size: 1.5rem;
            text-transform: uppercase;
            padding: 16px;
          }
          & .radio {
            margin-left: 16px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            font-size: 1.2rem;
            &__input {
              display: none;
            }

            &__radio {
              width: 20px;
              height: 20px;
              border: 1px solid #96aaa7;
              border-radius: 50%;
              margin-right: 10px;
              box-sizing: border-box;
              padding: 3px;
              &::after {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                background: #c22b05;
                border-radius: 50%;
                transform: scale(0);
              }
            }
            .radio__input:checked + .radio__radio::after {
              transform: scale(1);
            }
          }
        }
        &__btn {
          margin-top: 24px;
          padding: 20px 16px;
          justify-content: center;
          & .btn-pay {
            cursor: pointer;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
            height: 40px;
            text-transform: uppercase;
            color: $white;
            background-color: rgb(202, 33, 33);
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      &-orders {
        &__title {
          padding: 16px;
          font-size: 1.5rem;
          text-transform: uppercase;
          border-bottom: 1px solid #ccc;
        }
        &__list {
          padding: 20px 16px;
          height: 316px;
          overflow-y: auto;
          &-item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            & .photo {
              position: relative;
              & img {
                width: 80px;
                border-radius: 4px;
              }
              & span {
                padding: 2px 10px;
                border-radius: 50%;
                position: absolute;
                top: -10px;
                left: calc(100% - 14px);
                background-color: black;
                color: $white;
                font-size: 0.8rem;
              }
            }
            & .content {
              flex: 1;
              padding-left: 24px;
              padding-right: 24px;
              & .name {
                font-size: 1rem;
                color: $primary-text;
                line-height: 1.5rem;
                @include overflow-vertical;
                -webkit-line-clamp: 2;
                height: 51.2px;
              }
              & .size {
                font-size: 1rem;
                color: $primary-text;
              }
            }
            & .price {
              & p {
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.6rem;
              }
            }
          }
        }
        &__summary {
          padding: 16px;
          border-top: 1px solid #ccc;
          &__fee {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;
          }
        }
        &__total {
          padding: 24px 16px;
          border-top: 1px solid #ccc;
          margin-top: 32px;
          display: flex;
          justify-content: space-between;
          font-size: 1.2rem;
          & .price {
            font-size: 2rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
