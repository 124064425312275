.size {
  &-wrapper {
    width: 100%;
    align-self: center;
    border: 1px solid #ccc;
    margin-top: 40px;
    box-shadow: 0px 1px 5px #ccc;
  }
  &-header {
    @include heading-text;
  }
  &-body {
    &__image {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #ccc;
    }
    &__note {
      padding: 16px;
      &-title {
        @include text-title;
        color: $red-o;
      }
      & p,
      b {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
    &__video {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      & .video {
        & i {
          width: 640px;
          text-align: center;
          display: block;
          margin-top: 10px;
          font-size: 1rem;
          color: rgb(104, 47, 236);
        }
      }
    }
  }
}
