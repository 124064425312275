.cart {
  &-empty {
    width: 100%;
    text-align: center;
    height: 250px;
    padding-top: 50px;
    font-size: 2rem;
    text-transform: uppercase;
    & h3 {
      margin-bottom: 12px;
    }
    & .back-home {
      @include remove-a;
      color: $white;
      background-color: $color1;
    }
  }
  &-wrapper {
    width: 100%;
    align-self: center;
    border: 1px solid #ccc;
    margin-top: 40px;
    box-shadow: 0px 1px 5px #ccc;
  }
  &-header {
    @include heading-text;
  }
  &-form {
    & .scroll-table {
      max-height: 320px;
      overflow-y: auto;
    }
    &-table {
      font-size: 1.1rem;
      width: 100%;

      & th {
        padding: 8px 0;
        border-bottom: 1px solid #ccc;
      }
      & .checkbox {
        padding: 0 12px;
        font-size: 2rem;
        & input {
          cursor: pointer;
        }
      }
      & .image {
        width: 10%;
        text-align: center;
        &-link {
          display: block;
          img {
            height: 80px;
          }
        }
      }

      & .name {
        width: 50%;
        text-align: start;
        padding: 8px;
        &-link {
          @include remove-a;
          display: block;
          max-width: 570px;
          color: $primary-text;
          &-text {
            font-weight: 600;
            @include text-over;
          }
        }
      }
      & .quantity {
        width: 15%;
        text-align: center;
        &-ip {
          height: 30px;
          width: 60px;
          text-align: center;
          padding-left: 12px;
          color: $primary-text;
          font-size: 1rem;
          font-weight: 600;
          line-height: 2rem;
        }
      }
      & .price {
        width: 15%;
        text-align: end;
        padding: 8px;

        & p {
          width: 165px;
          @include text-over;
          font-weight: 600;
          user-select: none;
        }
      }

      & .remove {
        width: 10%;
        text-align: center;
        &-btn {
          cursor: pointer;
          border: transparent;
          font-size: 1rem;
          background-color: transparent;
          &:hover {
            color: $red-o;
          }
        }
      }
      & .summary {
        text-align: center;
        &-label {
          font-weight: 600;
        }
      }
      & tr td {
        border-bottom: 1px solid #ccc;
      }
      & tbody {
        height: 100px;
        overflow: auto;
      }
    }

    &-notes {
      padding: 25px 20px;

      &__title {
        font-size: 1rem;
        font-weight: 600;
      }
      &__content {
        display: block;
        min-width: 350px;
        max-width: 350px;
        min-height: 100px;
        max-height: 100px;
        list-style: 1.6rem;
        padding: 4px;
        color: $primary-text;
        border-radius: 4px;
      }
    }
    &-collections {
      margin-bottom: 25px;
      padding-left: 20px;
      font-size: 1.3rem;
      font-weight: 600;

      & a {
        @include remove-a;
        color: rgb(6, 79, 148);
        & span {
          margin-right: 4px;
          color: #555;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &-btn {
      margin-top: 80px;
      padding-right: 40px;
      float: right;

      &__update {
        margin-right: 20px;
        font-size: 1rem;
        font-weight: 600;
        height: 40px;
        background-color: $color-update;
        color: $white;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      &__payment {
        font-size: 1rem;
        font-weight: 600;
        height: 40px;
        background-color: $red-o;
        color: $white;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
