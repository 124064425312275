.banner {
  width: 100%;
  &__heading {
    @include title-center;
  }

  &-3 {
    display: block;
    @include remove-a;
    outline: none;
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    &__img {
      width: 100%;
      border-radius: 2px;
    }
    &__body {
      padding-top: 10px;
      text-transform: uppercase;
      @include text-over;
      &-title {
        @include text-title;
      }
      &-description {
        @include text-description;
      }
    }
    &::before {
      @include banner-before;
    }
    &:hover::before {
      @include banner-before-hover;
    }
    &:hover &__body-title {
      color: $hover-banner;
    }
  }
  .banner--hover {
    position: relative;
    width: 50px;
    height: 60px;
    background-color: #00a19d;
  }
  &-4 {
    display: block;
    @include remove-a;
    outline: none;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    &__img {
      width: 100%;
      border-radius: 2px;
    }
    &__title {
      padding-top: 4px;
      font-size: 1rem;
      font-weight: 500;
      text-transform: uppercase;

      &-text {
        color: $primary-text;
      }
      &-icon {
        color: #00a19d;
        margin-left: 4px;
      }
    }

    &:hover &__title-text {
      color: $hover-banner;
    }
    &::before {
      @include banner-before;
    }
    &:hover::before {
      @include banner-before-hover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    width: 100vw;
    &__heading {
      @include title-center-1024;
    }

    &-3 {
      &__body {
        padding-left: 15px;
        &-title {
          @include text-title;
        }
        &-description {
          @include text-description;
        }
      }
      &::before {
        @include banner-before;
      }
      &:hover::before {
        @include banner-before-hover;
      }
      &:hover &__body-title {
        color: $hover-banner;
      }
    }

    &-4 {
      display: block;
      @include remove-a;
      outline: none;
      position: relative;
      overflow: hidden;
      border-radius: 2px;
      &__img {
        width: 100%;
        border-radius: 2px;
      }
      &__title {
        padding-left: 15px;
        padding-top: 4px;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
}
