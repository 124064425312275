@for $i from 1 through $columns {
  .col-#{$i} {
    width: (100% / 12) * $i;
    flex: 0 0 (100% / 12) * $i;
    padding-left: 10px;
    padding-right: 10px;
  }
  @include responsive(300px, 765px) {
    .col-#{$i} {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

.col-2-4 {
  width: 20%;
  flex: 0 0 20%;
  padding-left: 10px;
  padding-right: 10px;
}
@include responsive(300px, 765px) {
  .col-2-4 {
    flex: 0 0 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@include responsive(766px, 1024px) {
  .col-2-4 {
    flex: 0 0 50%;
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.col-8-4 {
  width: 80%;
  flex: 0 0 80%;
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
