.detail {
  & .photo-container {
    width: 100%;
    & .photos {
      width: 100%;
      margin-top: 30px;
      & img {
        width: 410px;
        height: 410px;
      }
    }
    & .sub-photos {
      & img {
        width: 100%;
        cursor: pointer;
      }
      .slick-track {
        height: 411px !important;
      }
      .slick-prev {
        top: 0px;

        &::before {
          color: #fb1919;
        }
      }
      .slick-next {
        top: calc(100% + 40px);

        &::before {
          color: #fb1919;
        }
      }
      .slick-prev,
      .slick-next {
        border-radius: 4px;
        left: 42%;

        transform: rotate(90deg);
      }
      & .slick-list {
        top: 30px;
        border: 1px solid rgb(226, 220, 220);
      }
    }
  }
  & .body {
    width: 100%;
    &-heading {
      height: 80px;
      @include overflow-vertical;
      -webkit-line-clamp: 2;
      & h3 {
        font-size: 1.6rem;
        font-weight: 600;
        color: $primary-text;
        margin-bottom: 12px;
        text-transform: uppercase;
      }
    }

    &-price {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &__old {
        font-size: 1.2rem;
        font-weight: 500;
        margin-right: 8px;
        width: 85px;
        color: $second-text;
        @include text-over;
        text-decoration: line-through;
      }
      &__current {
        font-size: 2rem;
        font-weight: 600;
        color: $main-color;
        width: 150px;
        @include text-over;
      }
    }
    &-brand {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &__label {
        font-size: 1.2rem;
        color: $second-text;
        margin-right: 4px;
      }
      &__name {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $color2;
        @include text-over;
        width: 150px;
      }
    }
    &-size {
      margin-bottom: 20px;
      &__label {
        display: flex;
        & p {
          font-size: 1.2rem;
          color: $second-text;
          margin-right: 4px;
        }
        & a {
          @include remove-a;
          font-size: 1.2rem;
          color: $second-text;
          margin-right: 4px;
          font-style: italic;
          &:hover {
            color: $red-o;
          }
        }
      }
      &__options {
        list-style: none;
        display: flex;
        &-item {
          display: flex;
          text-align: center;
          min-width: 40px;
          max-width: 50px;
          align-items: center;
          margin-right: 8px;
          cursor: pointer;
          font-weight: 600;
          position: relative;

          &__input {
            display: none;
            &:checked + .body-size__options-item__label::after {
              transform: scale(1);
            }
            &:checked + .body-size__options-item__label::before {
              transform: scale(1);
            }
}
          &__label {
            width: 40px;
            position: relative;
            border: 1px solid $second-text;
            border-radius: 4px;
            font-size: 0.9rem;
            line-height: 36px;
            &-note {
              position: absolute;
              top: calc(100% + 10px);
              left: 0;
              min-width: 200px;
              border-radius: 4px;
              display: none;
              background-color: #c3d9f3;
              padding: 0 16px;

              &::before {
                content: "";
                position: absolute;
                top: -10px;
                left: 10px;
                border-bottom: 10px solid #c3d9f3;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
              }
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              border: 8px solid;
              border-color: $red-o transparent transparent $red-o;
              transform: scale(0);
              z-index: 1;
            }
            &::after {
              content: "";
              width: 32px;
              height: 30px;
              position: absolute;
              top: -1px;
              left: -1px;
              border: 4px solid $red-o;
              cursor: pointer;
              border-radius: 4px;
              transform: scale(0);
            }
            
          }
          .disable-option{
            opacity: 0.3;
            cursor: default;
          }
          &--selected {
            position: relative;
            border-color: $red-o;
            overflow: hidden;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              border: 8px solid;
              border-color: $red-o transparent transparent $red-o;
            }
          }

          &:hover .body-size__options-item__label-note {
            display: block;
          }
        }
        
      }
    }
    .rating-result{
      margin: 10px 0;
      display: flex;
      align-items: center;
      .rating{
        width: 120px;
        label{
          color: $red-o;
          margin: 5px 0;
          padding: 2px;
        }
        .star{
          position: relative;
          .star-1{
            position: absolute;
            top: 0;
            left: 0;
            width: 120px;
            overflow: hidden;
          }
        }
      }
      .seperate{
        width: 2px;
        height: 22px;
        background-color: #999;
        margin: 0 12px;
      }
      .review-count{
        font-size: 1.2rem;
        color: $primary-text
      }
    }
    &-quantity {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &__label {
        font-size: 1.2rem;
        font-weight: 600;
        margin-right: 4px;
        color: $second-text;
      }
&__input {
        width: 60px;
        font-size: 1.2rem;
        height: 28px;
        font-weight: 600;
        color: $primary-text;
        text-align: center;
        float: right;
      }
    }
    &-promotion {
      margin-bottom: 40px;
      &__title {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #e6ae47;
      }
      &__content {
        padding-left: 20px;
        & li {
          list-style: square;
          font-size: 1.2rem;
          font-weight: 600;
          padding-left: 8px;
        }
      }
    }
    &-btn {
      &__buy {
        background-color: $black;
        color: $white;
        font-size: 1.2rem;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        margin-right: 8px;
        cursor: pointer;
        &:hover {
          background-color: $red-o;
          color: $primary-text;
        }
        &.btn--disable {
          background-color: $black;
          color: $white;
        }
      }
      &__add-to-cart {
        background-color: #0586a7;
        color: $white;
        font-size: 1.2rem;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          background-color: $hover-link;
        }
        &.btn--disable {
          background-color: #0586a7;
          color: $white;
        }
      }
    }
  }
  & .description {
    @include section-detail-header;
    &-body {
      padding: 12px;
      & p {
        font-size: 1.1rem;
      }
    }
  }

  & .comments {
    @include section-detail-header;
    &-body {
      padding: 12px;
      border-radius: 4px;

      & p {
        font-size: 1.1rem;
      }
      &__rating {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        & .label {
          font-size: 1.2rem;
          color: $second-text;
          font-weight: 600;
        }
        & .rating {
          width: 140px;
          & input {
            display: none;
          }
          & label {
            color: #999;
            margin: 5px 0;
            padding-right: 5px;
            cursor: pointer;
            float: right;
          }
          & input:not(:checked) ~ label:hover,
          & input:not(:checked) ~ label:hover ~ label {
            color: $red-o;
          }
          & input:checked ~ label {
            color: $red-o;
          }
        }
        
      }
      &__form {
        margin-top: 10px;
        & .form-input {
          position: relative;
          display: flex;
          & img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
          &__content {
            margin-left: 10px;
          }
          &__label {
            font-size: 1.2rem;
            font-weight: 600;
            color: rgb(138, 88, 88);
          }
          &__text {
            min-width: 400px;
            max-width: 400px;
min-height: 90px;
            max-height: 90px;
            padding: 8px;
            font-size: 1.1rem;
            line-height: 1.5;
            overflow-y: auto;
            border-radius: 6px;
            background-color: #f3f3f3;
            &:focus {
              outline: 1px solid rgb(138, 88, 88);
            }
          }
          &__submit {
            background-color: $color2;
            color: $white;
            transition: linear all 0.2s;
            cursor: pointer;
            padding: 8px 10px;
            font-size: 1rem;
            border: none;
            border-radius: 50px;
            position: absolute;
            left: 29%;
            top: 90%;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      &__list {
        margin-top: 100px;
        border: 1px solid #ccc;
        box-shadow: 0px 1px 5px #ccc;
        padding: 12px;
        border-radius: 4px;

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          padding-left: 12px;
          & h3 {
            font-size: 1.5rem;
          }
        }
        &-body {
          padding: 12px;

          & .comment-item {
            padding: 12px;
            display: flex;
            box-shadow: 0px 1px 5px #ccc;
            border: 1px solid #ccc;
            background-color: #f5fffc;
            border-radius: 4px;
            margin-bottom: 12px;
            & .info {
              display: flex;
              margin-right: 24px;
              width: 200px;
              & img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
              }
              & .info-name {
                margin-left: 4px;
                margin-top: 8px;
              }
            }
            & .content {
              flex: 1;
              padding-right: 30px;
              & .rating {
                & path {
                  color: $red-o;
                  margin: 5px 0;
                  padding-right: 5px;
                }
                & svg:nth-child(5) {
                  padding-right: 0;
                }
                & .star {
                  position: relative;
                }
                & .star-1 {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 84px;
                  overflow: hidden;
                }
              }
              &-body {
                min-height: 28px;
              }
            }
          }
          & .create-date {
            width: 180px;
            font-size: 1rem;
            padding-left: 12px;
          }
        }
      }
    }
  }
}
