.account {
  &-wrapper {
    width: 100%;
    margin-top: 40px;
    &__information {
      box-shadow: 0px 1px 5px #ccc;
      &__heading {
        @include heading-text;
      }
      &__body {
        & form {
          padding: 16px;
          & .form-header {
            display: flex;
            margin-bottom: 12px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 16px;
            &__img {
              position: relative;
              & img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
              }
              &-edit {
                position: absolute;
                bottom: 0;
                right: 5px;
                cursor: pointer;
                border-radius: 5px;
                &-btn {
                  display: none;
                }
                & svg {
                  background-color: rgba(231, 213, 213, 0.2);
                  color: rgba(63, 63, 63, 0.7);
                  &:hover {
                    background-color: rgba(68, 64, 64, 0.5);
                    color: $black;
                  }
                }
              }
            }
            &__username {
              flex: 1;
              justify-self: center;
              padding: 30px 24px;

              & h5 {
                font-size: 1.2rem;
                line-height: 44px;
              }
            }
          }

          & .form-control {
            margin-bottom: 16px;
            & label {
              display: block;
              font-size: 1.2rem;
            }
            & input {
              height: 40px;
              width: 100%;
              padding: 8px 16px;
              font-size: 1rem;
            }
          }
          & .form-btn {
            align-items: center;
            margin-top: 36px;
            margin-bottom: 20px;
            padding: 12px 8px;
            background-color: $color-update;
            color: $white;
            cursor: pointer;
            font-size: 1rem;
            margin-left: 50%;
            transform: translateX(-50%);
            &:hover {
              opacity: 0.8;
            }
          }
          & .form-password {
            @include text-title;
            background-color: #d34d4d;
            cursor: pointer;
            width: 150px;
            color: $white;
            text-align: center;
            margin-bottom: 25px;
            position: absolute;
            right: 0;
          }
          & .btn-otp {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5;
            padding: 8px;
            color: $white;
            background-image: linear-gradient(45deg, #cf3c17 30%, #185d66 90%);
            text-transform: uppercase;
            margin-left: 8px;
            height: 40px;
            cursor: pointer;
            align-self: center;
            &:hover {
              background-image: linear-gradient(
                45deg,
                #185d66 30%,
                #cf3c17 90%
              );
            }
            &--disbaled {
              cursor: no-drop;
              opacity: 0.6;
            }
          }
        }
      }
    }
    &__address {
      box-shadow: 0px 1px 5px #ccc;
      &__heading {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 12px 16px;
        & h3 {
          font-size: 2rem;
          text-transform: uppercase;
          color: $primary-text;
        }
        & .add-btn {
          font-size: 1rem;
          background-color: #e01919;
          font-weight: 600;
          color: $white;
          font-size: 0.9rem;
          padding: 0 12px;
          line-height: 50.4px;
          cursor: pointer;
          border-radius: 2px;
          &:hover {
            background-color: #d34d4d;
          }
        }
      }
      &__form {
        & form {
          padding: 16px;
          & .add-form-btn {
            display: flex;
            justify-content: space-evenly;
            & .form-btn {
              align-items: center;
              margin-top: 20px;
              margin-bottom: 20px;
              padding: 12px 8px;
              background-color: rgb(3, 75, 47);
              color: $white;
              cursor: pointer;
              font-size: 1rem;

              &--cancel {
                background-color: $red-o;
              }
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
      &__body {
        height: 428.2px;
        overflow-y: auto;
        & .address-item {
          display: flex;
          height: 76px;
          align-items: center;
          border-bottom: 1px solid #ccc;
          &__icon {
            padding: 0 16px;
            & svg {
              color: rgba(231, 213, 213, 0.2);
            }
          }
          &__content {
            flex: 1;
            font-size: 1.2rem;
            height: 60.8px;
            padding: 0 16px;
            @include overflow-vertical;
            -webkit-line-clamp: 2;
          }
          &__setup {
            font-size: 0.9rem;
            padding: 8px 16px;
            cursor: pointer;
            margin: 0 8px;
            border-radius: 2px;
            font-weight: 600;
            &:hover {
              opacity: 0.8;
            }
          }
          &__setup--config {
            background-color: rgb(1, 131, 70);
            color: $white;
          }
          &__setup--delete {
            background-color: $red-o;
            color: $white;
          }
        }
      }
    }

    &__order {
      box-shadow: 0px 1px 5px #ccc;
      margin-top: 40px;
      &__heading {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border-bottom: 1px solid #ccc;
        align-items: center;
        & h3 {
          font-size: 2rem;
          text-transform: uppercase;
          color: $primary-text;
        }
        & .status-sort {
          &-label {
            margin-right: 4px;
            font-size: 1rem;
          }
          & select {
            padding: 8px;
            cursor: pointer;
          }
        }
      }
      &__body {
        padding-bottom: 16px;
        & .order-item {
          margin: 32px 16px;
          box-shadow: 0px 1px 5px #ccc;
          &__heading {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid #ccc;
            align-items: center;
            &-id {
              font-size: 1.5rem;
            }
            &-status {
              padding-right: 36px;
              font-size: 1.2rem;
              color: rgb(25, 177, 83);
            }
          }
          &__body {
            padding: 16px;
            &-list-item {
              list-style: none;
              & .item {
                margin-bottom: 10px;
                &-link {
                  display: flex;
                  @include remove-a;
                  padding: 16px;
                  align-items: center;
                  border: 1px solid #cccc;
                  border-radius: 2px;
                  & .photo {
                    & img {
                      border: 1px solid #cccc;
                      width: 100px;
                      height: auto;
                      border-radius: 2px;
                    }
                  }
                  & .content {
                    flex: 1;
                    padding: 0 16px;
                    &__name {
                      font-size: 1.2rem;
                      color: $primary-text;
                    }
                    &__quantity,
                    &__size {
                      font-size: 1rem;
                      color: $second-text;
                    }
                  }
                  & .price {
                    width: 150px;
                    text-align: center;
                    &__old {
                      font-size: 0.9rem;
                      text-decoration: line-through;
                      color: $second-text;
                    }
                    &__current {
                      font-size: 1.2rem;
                      font-weight: 600;
                      color: $main-color;
                    }
                  }
                }
              }
            }
            & .summary {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              padding: 16px 0;
              align-items: center;
              &-payments {
                padding-right: 16px;
                font-size: 1.2rem;
                border-right: 2px solid #ccc;
                & span {
                  text-transform: uppercase;
                }
              }
              &-money {
                padding: 0 8px 0 16px;
                display: flex;
                font-size: 1.2rem;
                &__icon {
                  padding-right: 4px;
                }
              }
              &-price {
                font-size: 1.8rem;
                font-weight: 600;
                color: $red-o;
                padding-right: 36px;
              }
            }

            & .button {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              & .btn-cancel {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 600;
                color: $white;
                background-color: $red-o;
                text-align: center;
                margin-right: 50px;
                margin-bottom: 16px;
                cursor: pointer;
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }
  }
  .error-input {
    font-size: 1rem;
    padding: 4px;
    color: crimson;
    font-weight: 500;
  }
}

.MuiList-root {
  height: 200px !important  ;
}
