.policy {
  width: 100%;
  position: relative;
  padding-top: 45px;
  &-item {
    display: flex;
    cursor: default;
    &__img {
      width: 50px;
      height: 50px;
      margin-top: 12px;
    }
    &__body {
      padding-left: 15px;
      &-title {
        @include text-title;
      }
      &-description {
        @include text-description;
        @include overflow-vertical;
        -webkit-line-clamp: 3;
      }
    }

    &:hover &__img {
      animation: $rorate 2s infinite;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 1px;
    background-color: #ccc;
  }
}

.col-seperate {
  position: relative;
  &:not(:last-child):after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 68px;
    background-color: rgb(184, 187, 186);
  }
}
// @media screen and (max-width: 1024px) {
//   .policy {
//     width: 100vw;
//     &-item {
//       &__body {
//         &-description {
//           width: 300px;
//         }
//       }
//     }

//     &::before {
//       width: 500px;
//     }
//   }
// }

// @media screen and (max-width: 765px) {
//   .policy {
//     width: 100vw;
//     &-item {
//       &__body {
//         &-description {
//           width: 300px;
//         }
//       }
//     }

//     &::before {
//       width: 240px;
//     }
//   }
// }
