.header {
  width: 100%;
  height: 120px;
  padding: 10px 0;
  @include backgroundHeader;
}
@media screen and (max-width: 1024px) {
  .header {
    height: 76px;
  }
}
