.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  & .confirm {
    z-index: 1000;
    text-align: center;
    width: 600px;
    padding: 40px;
    background-image: linear-gradient(180deg, $color2, $color1);
    color: #fff;
    display: block;
    border-radius: 4px;
    & h1 {
      margin-bottom: 45px;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
    & .btn-container {
      display: flex;
      justify-content: space-evenly;
      & button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        padding: 8px;
        & .icon {
          display: flex;
          align-items: center;
        }
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
