.slides {
  width: 100%;
  height: 520px;
  margin-bottom: 45px;

  & img {
    width: 100%;
    height: 520px;
    cursor: pointer;
  }
  & .slick-dots {
    position: absolute;
    bottom: 25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  & .slick-prev {
    left: 32px;
    z-index: 1;
  }
  & .slick-next {
    right: 32px;
  }
  & .slick-prev,
  & .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: red;
    border: none;
    outline: none;
    background: transparent;
  }

  & .slick-prev:before,
  & .slick-next:before {
    font-family: "slick";
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
  }
  & .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: rgb(248, 248, 248);
  }
  & .slick-dots li button:before {
    font-family: "slick";
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: rgb(187, 178, 178);
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (max-width: 1024px) {
  .slides {
    height: 100%;
    width: 100%;
    margin-bottom: 45px;
    & img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    & .slick-prev,
    & .slick-next {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: red;
      border: none;
      outline: none;
      background: transparent;
    }
  }
}
