.about {
  .wrapper {
    align-self: center;
    border: 1px solid #ccc;
    margin-top: 40px;
    box-shadow: 0px 1px 5px #ccc;
    display: flex;
    .about-3 {
      width: 33.33333%;

      &-1 {
        display: flex;
        width: 100%;
        & img {
          width: 100%;
        }
        &-2 {
          width: 50%;
          & img {
            width: 100%;
          }
        }
      }
    }
  }
  img:hover {
    transition: linear 0.2s;
    transform: scale(1.02);
  }

  &-heading {
    padding: 16px;
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  &-content {
    padding: 16px;
    font-size: 1.2rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    & p {
      text-align: justify;
      line-height: 1.6rem;
      margin-bottom: 10px;
    }
    & p::first-letter {
      padding-left: 10px;
    }
  }
}
